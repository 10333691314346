import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  CheckIcon,
  ChevronDownIcon,
  CircleCheckIcon,
  CloudUpload,
  CrossIcon,
  DocUploadIcon,
  MaximizeIcon,
  SemiCircleIcon,
  TrashIcon
} from '../Icons/Icons'
import Button2 from '../Button/Button2'
import Modal from '../Lexical/ui/Modal'
import { Dialog, Fade, Popover } from '@mui/material'
import DocumentSvg, {
  ReactComponent as DocumentIcon
} from '../../assets/svg/FileIcons/Document.svg'
import PDFSvg, {
  ReactComponent as PdfIcon
} from '../../assets/svg/FileIcons/PDF.svg'
import PPTSvg, {
  ReactComponent as PPTIcon
} from '../../assets/svg/FileIcons/PPT.svg'
import DOCSvg, {
  ReactComponent as DOCIcon
} from '../../assets/svg/FileIcons/DOC.svg'
import ImageSvg, {
  ReactComponent as ImageIcon
} from '../../assets/svg/FileIcons/Image.svg'
import VideoSvg, {
  ReactComponent as VideoIcon
} from '../../assets/svg/FileIcons/Video.svg'
import ProposalImageSvg, {
  ReactComponent as ProposalImageIcon
} from '../../assets/svg/FileIcons/ProposalImage.svg'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import AwsS3Multipart from '../../utils/uppy/aws-multipart'
import Uppy from '../../utils/uppy/Uppy'
import { isEmpty } from 'lodash'
import SearchNew from '../../sections/ListFilter/SearchNew'
import { useSelector } from 'react-redux'
import HeadlessTable from '../TableV2/HeadlessTable'
import { useDropzone } from 'react-dropzone'
import PdfViewer from '../../pages/PdfViewer'
import useConfirmation from '../ConfirmDialog'
import { deleteProposals } from '../../store/api'
import { formatTimeAgo } from '../../utils/Date'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { format } from 'date-fns'
import moment from 'moment'

const getFileIcon = (file) => {
  const { other_data, file_type, file_extension = '', name, type } = file || {}

  console.log('file name', name, type)
  if (name?.includes('ppt')) {
    return <PPTIcon />
  } else if (name?.includes('doc')) {
    return <DOCIcon />
  }

  if (name?.includes('pdf')) {
    return <PdfIcon />
  }

  if (type.includes('image')) {
    return <ImageIcon />
  }

  // if (file_type === 'proposal') {
  //   return <PdfIcon />
  // } else if (file_type === 'image') {
  // } else if (file_type === 'video') {
  //   return <VideoIcon />
  // } else if (file_type === 'proposal image') {
  //   return <ProposalImageIcon />
  // } else if (file_type === 'collection') {
  //   const { is_public, access_config } = other_data
  //   const { user_ids = [] } = access_config || {}
  //   //   if (is_public) {
  //   //     return <PublicFolder />
  //   //   } else if (!is_public && user_ids.length > 0) {
  //   //     return <SharedFolder />
  //   //   } else {
  //   //     return <FolderIcon />
  //   //   }
  // } else if (file_type === 'document' || file_type === 'resume') {
  //   if (file_extension?.includes('ppt')) {
  //     return <PPTIcon />
  //   } else if (file_extension?.includes('doc')) {
  //     return <DOCIcon />
  //   } else if (file_extension?.includes('pdf')) {
  //     return <PdfIcon />
  //   }
  //   return <DocumentIcon />
  // }
}

const AttachFilesUI = ({
  files,
  profileId,
  acceptedFileType = '.pdf',
  refresh,
  source = 'people' | 'project' | 'client'
}) => {
  const [localFiles, setLocalFiles] = React.useState([])
  const [filesModal, setFilesModal] = React.useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()

  const handleFileUpload = (e) => {
    const files = e.target.files

    console.log('files', files)
    if (files.length > 0) {
      setFilesModal(true)
      setLocalFiles([...files])
    }
  }

  const onDrop = (acceptedFiles) => {
    console.log('dropped files', acceptedFiles)

    handleFileUpload({ target: { files: acceptedFiles } })
  }

  const onDropFunction = useCallback((acceptedFiles) => {
    onDrop(acceptedFiles)
  }, [])

  const onDropRejected = useCallback((fileRejections) => {
    const { errors } = fileRejections[0]
    if (errors[0].code === 'file-invalid-type') {
      toast.error(`Invalid file type. Supports only ${fileType} files.`)
    } else if (errors[0].code === 'file-too-large') {
      toast.error('File size exceeds the limit.')
    } else {
      toast.error(errors?.[0]?.message || 'Something went wrong.')
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: onDropFunction,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
    useFsAccessApi: false,
    accept: {
      application: [acceptedFileType]
    }
    // ...otherProps
  })

  const handleDeleteDocument = async (row) => {
    showConfirmDialog({
      deleteType: true,
      onConfirm: async () => {
        const { id } = row

        const response = await deleteProposals(id)

        if (response) {
          refresh()
        }
      },
      confirmationMessageTitle: `Are you sure you want to delete the document "${
        row?.proposal_name || ''
      }" ?`
    })
  }

  if (files.length === 0) {
    return (
      <div
        className="grid place-content-center h-full text-center"
        style={{
          border: '1px dashed var(--grey-300)',
          height: 'calc(100% - 50px)',
          borderRadius: '10px',
          margin: '20px'
        }}
        {...getRootProps({})}
      >
        <div className="flex flex-col gap-1 items-center">
          <DocUploadIcon className="size-12 text-grey-500" />
          <p className="text-base m-0 text-grey-700">Drop your files here</p>
          <p className="m-0 text-grey-500 text-xxs">or</p>
          <label htmlFor="docUpload" className="cursor-pointer mt-2">
            <input
              {...getInputProps()}
              type="file"
              multiple
              className="hidden"
              accept="application/pdf"
              id="docUpload"
              onInput={(e) => handleFileUpload(e)}
            />
            <div
              className="flex items-center gap-1 text-xs bg-grey-800 text-white rounded-lg"
              style={{ padding: '6px 20px' }}
            >
              Choose Files
            </div>
          </label>
        </div>
        {filesModal && (
          <FilesModal
            source={source}
            refresh={() => {
              refresh()
              setFilesModal(false)
              document.getElementById('docUpload').value = null
            }}
            acceptedFileType={acceptedFileType}
            profileId={profileId}
            isOpen={filesModal}
            onClose={() => {
              setFilesModal(false)
              document.getElementById('docUpload').value = null
            }}
            files={localFiles}
            setFiles={() => {
              setLocalFiles([])
            }}
          />
        )}
      </div>
    )
  }

  const columns = [
    {
      id: 'name',
      label: 'File Name',
      accessorKey: 'proposal_name',
      children: (row) => {
        return (
          <div className="flex gap-2 items-center w-44">
            <div className="size-4">
              {getFileIcon({ name: row.proposal_name, type: row.file_type })}
            </div>
            <p
              className="m-0 text-xxs text-left"
              style={{
                width: '80%'
              }}
            >
              {row.proposal_name}
            </p>
          </div>
        )
      }
    },
    {
      id: 'uploaded_at',
      label: 'Uploaded At',
      accessorKey: 'created_at',
      children: (row) => {
        return (
          <p className="m-0 text-xxs">
            {' '}
            {moment.utc(row.created_at).local().format('ll')}
          </p>
        )
      }
    },
    {
      id: '',
      label: '',
      children: (row) => {
        return (
          <div className="flex items-center ml-auto gap-1">
            <Button2
              secondary
              noOutline
              style={{
                padding: '6px',
                borderRadius: '6px'
              }}
              onClick={(e) => {
                e.stopPropagation()
                handleDeleteDocument(row)
              }}
            >
              <TrashIcon className="size-4" />
            </Button2>
            <Button2
              secondary
              style={{
                padding: '6px',
                borderRadius: '6px'
              }}
            >
              <MaximizeIcon className="size-4" />
            </Button2>
          </div>
        )
      }
    }
  ]

  return (
    <div
      className=""
      style={{
        height: 'calc(100% - 100px)'
        // marginTop: '20px'
      }}
    >
      <div className="mt-4 flex justify-end mb-2">
        <label htmlFor="docUpload" className="cursor-pointer">
          <input
            type="file"
            multiple
            className="hidden"
            accept="application/pdf"
            id="docUpload"
            onInput={(e) => handleFileUpload(e)}
          />
          <div
            className="flex items-center gap-2 text-xxs bg-grey-800 text-white rounded-lg font-medium"
            style={{ padding: '6px 16px' }}
          >
            <CloudUpload className="size-4" />
            Choose Files
          </div>
        </label>
      </div>
      <HeadlessTable
        data={files}
        columns={columns}
        containerStyle={{
          height: '100%'
        }}
        onRowClick={(row) => {
          setSelectedFile(row)
        }}
        style={{
          height: 'calc(100% - 100px)',
          overflow: 'auto'
        }}
        hidePagination
      />
      {ConfirmDialog}
      {filesModal && (
        <FilesModal
          source={source}
          refresh={() => {
            console.log('refresh')
            refresh()
            setFilesModal(false)
            document.getElementById('docUpload').value = null
          }}
          dropZone={{
            // onDrop: onDropFunction,
            root: { ...getRootProps({}) },
            input: { ...getInputProps() }
          }}
          profileId={profileId}
          isOpen={filesModal}
          onClose={() => {
            setFilesModal(false)
            document.getElementById('docUpload').value = null
          }}
          files={localFiles}
          setFiles={() => {
            setLocalFiles([])
          }}
        />
      )}

      {selectedFile && (
        <ViewFile
          isOpen={!!selectedFile}
          onClose={() => {
            setSelectedFile(null)
          }}
          file={selectedFile}
          source={source}
          profileId={profileId}
        />
      )}
    </div>
  )
}

export default AttachFilesUI

const FilesModal = ({
  files,
  setFiles,
  isOpen,
  onClose,
  profileId,
  dropZone,
  acceptedFileType = '.pdf',
  refresh,
  source
}) => {
  const [uppy, setUppy] = useState({})
  const [uploadedFiles, setUploadedFiles] = useState(files)
  const [tempFiles, setTempFiles] = useState(files)
  // const [filesProperties, setFilesProperties] = useState(null)
  const filesProperties = useRef(null)
  const [currentUploadPercentage, setCurrentUploadPercentage] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const tagsCenterStateTags = useSelector((state) => state?.tagCenter?.tags)
  const [documentTypes, setDocumentTypes] = useState(null)

  const onDropFunction = useCallback((acceptedFiles) => {
    onDrop(acceptedFiles, documentTypes)
  }, [])

  const onDropRejected = useCallback((fileRejections) => {
    const { errors } = fileRejections[0]
    if (errors[0].code === 'file-invalid-type') {
      toast.error(`Invalid file type. Supports only ${fileType} files.`)
    } else if (errors[0].code === 'file-too-large') {
      toast.error('File size exceeds the limit.')
    } else {
      toast.error(errors?.[0]?.message || 'Something went wrong.')
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: onDropFunction,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
    useFsAccessApi: false,
    accept: {
      application: [acceptedFileType]
    }
    // ...otherProps
  })

  const onDrop = (acceptedFiles) => {
    setTempFiles([...tempFiles, ...acceptedFiles])

    const docTypes = getDocTypes()
    const fileProperties = Array.from(acceptedFiles).map((file) => ({
      document_tag: docTypes.find((item) => {
        if (source === 'people') {
          return item.label === 'Resume'
        }

        return item.label === 'Document'
      }),
      name: file.name
    }))

    const tempFileProps = [...filesProperties.current]

    fileProperties.forEach((item) => {
      tempFileProps.push(item)
    })

    filesProperties.current = tempFileProps
    // setFilesProperties(tempFileProps)
  }

  useEffect(() => {
    if (!isEmpty(uppy)) {
      uppy.on('file-added', (file) => {
        refreshFileState()
      })

      uppy.on('file-removed', () => {
        refreshFileState()
      })
      uppy.on('upload-progress', () => {
        refreshFileState()
      })
      uppy.on('progress', (progress) => {
        setCurrentUploadPercentage(progress)
        if (progress > 0 && progress < 100 && !isUploading) {
          // setIsUploading(true)
        } else {
          refreshFileState()
        }
      })
      uppy.on('upload-error', (file, error, response) => {
        refreshFileState()
      })
      uppy.on('complete', (result) => {
        setTimeout(() => {
          refresh()
          setIsUploading(false)
        }, 3000)

        // if (uploaderType === 'resource') {
        //   modalCallback(result, 'onComplete')
        // } else if (uploaderType === 'analytics') {
        //   dispatch(fetchAnalytics())
        // } else if (uploaderType === 'rfx') {
        //   dispatch(fetchRFxDocuments())
        // }
      })
      refreshFileState()
    }
  }, [uppy])

  useEffect(() => {
    async function initalize() {
      const newUppy = new Uppy({
        autoProceed: true,
        restrictions: {
          allowedFileTypes: [
            'application/pdf',
            'video/mp4',
            'video/webm',
            'video/mpeg',
            'video/ogg',
            'video/avi',
            'image/jpeg',
            'image/png',
            'image/jpg',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          ]
        },
        showSelectedFiles: true
      }).use(AwsS3Multipart, {
        limit: 4,
        companionUrl: process.env.REACT_APP_API_GATEWAY_URL,
        uploaderType: 'proposal'
      })
      setUppy(newUppy)
    }
    initalize()
  }, [])

  useEffect(() => {
    const options2 = getDocTypes()
    const selectedOption = options2.find((item) => {
      if (source === 'people') {
        return item.label === 'Resume'
      }

      return item.label === 'Document'
    })

    const ImageOption = options2.find((item) => item.label === 'Image')

    const finalProps = tempFiles.map((file) => {
      if (file.type.includes('image')) {
        return { document_tag: ImageOption, name: file.name }
      }

      return { document_tag: selectedOption, name: file.name }
    })

    console.log('finalProps', finalProps)

    filesProperties.current = finalProps
    // setFilesProperties(finalProps)
    getDocTypes()
  }, [])

  const getDocTypes = () => {
    const values = Object.entries(tagsCenterStateTags || {}).reduce(
      (acc, [key, item]) => {
        if (item.value_type?.includes('document_type')) {
          acc.push({ key, ...item })
        }
        return acc
      },
      []
    )
    const options = values[0]?.data
      ? values[0]?.data
          .filter((item) => {
            return item.value
          })
          .map((item) => {
            return {
              value: item.id,
              label: item.value,
              value_type: item.value_type
            }
          })
      : []

    setDocumentTypes(options)

    return options
  }
  const refreshFileState = () => {
    const uppyfiles = uppy.getFiles()
    setUploadedFiles(uppyfiles)
  }

  const addFiles = (files) => {
    const descriptors = files.map((file, index) => ({
      source: 'resource',
      name: file.name,
      extension: file.name.split('.').pop(),
      type: file.type,
      data: file,
      meta: {
        // document_tag: {
        //   value: 'bcb72e89-ea07-425a-88b0-d51dc8a2853b',
        //   label: 'Document',
        //   value_type: 'document_type_Document_default'
        // }
        document_tag: {
          ...filesProperties.current[index].document_tag
        }
      },
      profile_id: profileId
    }))
    try {
      console.log('descriptors', descriptors)
      const event = {
        people: mixpanelEvents.PEOPLE_FILES_UPLOADED,
        client: mixpanelEvents.CLIENT_FILES_UPLOADED,
        project: mixpanelEvents.PROJECT_FILES_UPLOADED
      }
      trackEvent(
        event[source],
        'SUCCESS',
        {},
        {
          profile_id: profileId,
          files: descriptors
        }
      )
      setIsUploading(true)
      uppy.addFiles(descriptors)
    } catch (err) {
      refreshFileState()
      console.log(err)
    }
  }

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Fade}
      keepMounted
      onClose={() => {
        if (!isUploading) {
          onClose()
        }
      }}
    >
      <div
        className="p-4"
        style={{
          width: '500px',
          minHeight: '200px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px'
        }}
      >
        <label htmlFor="moreFiles">
          <div
            style={{
              height: '100px',
              border: '1px dashed var(--grey-300)'
            }}
            // {...dropZone.root}
            {...getRootProps()}
            className="bg-grey-100 rounded-md grid place-content-center cursor-pointer"
          >
            <input
              id="moreFiles"
              type="file"
              multiple
              {...getInputProps()}
              className="hidden"
              onChange={(e) => {
                const files = e.target.files
                setTempFiles([...tempFiles, ...files])

                const fileProperties = Array.from(files).map((file) => ({
                  document_tag: documentTypes.find(
                    (item) => item.label === 'Document'
                  ),
                  name: file.name
                }))

                filesProperties.current = [
                  ...filesProperties.current,
                  ...fileProperties
                ]
              }}
            />
            <div className="flex flex-col justify-center items-center text-xxs">
              <DocUploadIcon className="size-6 text-grey-500" />
              <p className="m-0 mt-1">Drop more files here</p>
            </div>
          </div>
        </label>

        {documentTypes && filesProperties && (
          <div className="flex flex-col gap-2">
            {uploadedFiles.length > 0
              ? uploadedFiles.map((file, index) => (
                  <FileListItem
                    key={index}
                    file={file}
                    documentOptions={documentTypes}
                    properties={filesProperties.current[index]}
                    disableEdit={true}
                    removeFile={() => {
                      const filter = uploadedFiles.filter((f, i) => i !== index)
                      setUploadedFiles(filter)
                    }}
                  />
                ))
              : tempFiles.map((file, index) => (
                  <FileListItem
                    editable={true}
                    key={index}
                    file={file}
                    totalFiles={tempFiles.length}
                    documentOptions={documentTypes}
                    properties={filesProperties.current[index]}
                    removeFile={() => {
                      const filter = tempFiles.filter((f, i) => i !== index)
                      setTempFiles(filter)

                      const filterProperties = filesProperties.current.filter(
                        (f, i) => i !== index
                      )

                      filesProperties.current = filterProperties

                      // setFilesProperties(filterProperties)
                    }}
                    updateProperties={(properties) => {
                      const updatedProperties = filesProperties.current.map(
                        (item, i) => (i === index ? properties : item)
                      )

                      filesProperties.current = updatedProperties
                      // setFilesProperties(updatedProperties)
                    }}
                  />
                ))}
          </div>
        )}
        <div className="flex items-center justify-end mt-auto">
          {!isUploading && (
            <Button2 secondary noOutline onClick={() => onClose()}>
              Close
            </Button2>
          )}
          {isUploading ? (
            <div
              className="p-2 rounded-lg text-white text-xxs flex items-center gap-2 font-medium"
              // secondary
              // onClick={() => addFiles(files)}
              style={{
                backgroundColor: '#ffedd5',
                color: '#ea580c'
              }}
            >
              <SemiCircleIcon className="size-4 animate-spin" />
              Uploading
            </div>
          ) : (
            <Button2
              primary
              onClick={() => addFiles(tempFiles)}
              style={{
                backgroundColor: isUploading && '#f97316 !important'
              }}
            >
              Upload
            </Button2>
          )}
        </div>
      </div>
    </Dialog>
  )
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        size={32}
        {...props}
        // sx={{ height: '14px', width: '14px' }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.secondary', fontSize: '10px' }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

const FileListItem = ({
  file,
  removeFile,
  documentOptions,
  properties,
  updateProperties,
  disableEdit,
  totalFiles = 0
}) => {
  const [progress, setProgress] = React.useState(
    file?.progress?.percentage ?? 0
  )

  const [filteredTagsOptions, setFilteredTagsOptions] =
    useState(documentOptions)

  const [selectedDocumentType, setSelectedDocumentType] = useState(null)

  console.log('file properties', properties, selectedDocumentType)

  const [searchTag, setSearchTag] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  useEffect(() => {
    console.log('percentage', file?.progress?.percentage)
    setProgress(file?.progress?.percentage ?? 0)
  }, [file?.progress?.percentage])

  useEffect(() => {
    console.log('selected', properties)
    setSelectedDocumentType(properties?.document_tag)
  }, [properties?.document_tag])

  return (
    <div className="flex items-center gap-2 bg-grey-100 p-2 rounded-lg text-grey-800">
      <div className="size-6">{getFileIcon(file)}</div>
      <div
        className="flex flex-col gap-1"
        style={{
          width: '80%'
        }}
      >
        <p className="m-0 text-xxs">{file.name}</p>
        <p
          className="m-0 bg-grey-700 rounded-full w-fit text-white flex items-center justify-center gap-1"
          style={{
            padding: '3px 8px',
            paddingLeft: '10px',
            fontSize: '10px',
            pointerEvents: disableEdit && 'none'
          }}
          onClick={handleClick}
        >
          {selectedDocumentType?.label} <ChevronDownIcon className="size-3" />
        </p>

        <Popover
          open={open}
          anchorEl={anchorEl}
          TransitionComponent={Fade}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <div className="overflow-hidden p-2">
            <SearchNew
              value={searchTag}
              onChange={(value) => {
                setSearchTag(value)

                setFilteredTagsOptions(
                  documentOptions.filter((item) =>
                    item.label.toLowerCase().includes(value.toLowerCase())
                  )
                )
              }}
            />

            <div className="flex flex-col h-36 overflow-auto mt-1 px-1">
              {filteredTagsOptions.map((item, index) => (
                <Button2
                  secondary
                  noOutline
                  onClick={() => {
                    updateProperties({ ...properties, document_tag: item })
                    setSelectedDocumentType(item)
                    handleClose()
                  }}
                  // className="m-0 text-xxs px-4"
                  key={index}
                >
                  {item.label}
                </Button2>
              ))}
            </div>
          </div>
        </Popover>
      </div>
      <div className="flex items-center ml-auto">
        {!progress ? (
          <div
            className="px-2 text-grey-500 grid place-content-center cursor-pointer"
            onClick={removeFile}
            style={{
              display: totalFiles === 1 ? 'none' : 'block'
            }}
          >
            <CrossIcon className="size-4" />
          </div>
        ) : progress >= 100 ? (
          <CircleCheckIcon className="size-4 mr-2" />
        ) : (
          <CircularProgressWithLabel value={progress} />
        )}

        {/* {progress < 100 ||
          (!progress && (
            <div className="px-2 text-grey-500 grid place-content-center">
              <CrossIcon className="size-4" />
            </div>
          ))} */}
      </div>
    </div>
  )
}

const ViewFile = ({ file, isOpen, onClose, source, profileId }) => {
  useEffect(() => {
    const event = {
      people: mixpanelEvents.PEOPLE_FILES_OPENED,
      client: mixpanelEvents.CLIENT_FILES_OPENED,
      project: mixpanelEvents.PROJECT_FILES_OPENED
    }

    trackEvent(
      event[source],
      'SUCCESS',
      {},
      {
        profile_id: profileId,
        file_id: file.id
      }
    )
  }, [])

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Fade}
      keepMounted
      onClose={() => onClose()}
      maxWidth="xl"
    >
      <div
        style={{
          height: '550px',
          width: '900px'
        }}
      >
        <PdfViewer
          id={file?.id}
          file_type={file?.file_type}
          onUpdateCallback={() => {}}
          onClose={() => {
            onClose()
          }}
          // activeCollection={activeCollection}
          pageNo={0}
        />
      </div>
    </Dialog>
  )
}
