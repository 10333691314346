import { useCallback, useEffect, useRef, useState } from 'react'
import './index.css'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  COMMAND_PRIORITY_CRITICAL,
  SELECTION_CHANGE_COMMAND,
  $createParagraphNode,
  $createTextNode,
  $getSelection,
  $isRangeSelection,
  KEY_ENTER_COMMAND,
  INSERT_LINE_BREAK_COMMAND,
  INSERT_PARAGRAPH_COMMAND
} from 'lexical'
import { createPortal } from 'react-dom'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { isHTMLElement } from '../../utils/guard'
import { Point } from '../../utils/point'
import { Rect } from '../../utils/rect'
import {
  $getSectionElementById,
  $getSectionOtherElementsById,
  $isSectionElementNode,
  $getFirstParaOfSection
} from '../../nodes/SectionNode'
import {
  CAN_USE_BEFORE_INPUT,
  IS_IOS,
  IS_SAFARI
} from '../../shared/src/environment'

const SPACE = 4
const SECTION_BLOCK_MENU_CLASSNAME = 'floating-section'

const Downward = 1
const Upward = -1
const Indeterminate = 0

let prevIndex = Infinity

function getTopMostParent(node) {
  const parentNode = node.getParent()
  if (!parentNode || node.__type === 'section') {
    return node
  }
  return getTopMostParent(parentNode)
}

function getCurrentIndex(keysLength) {
  if (keysLength === 0) {
    return Infinity
  }
  if (prevIndex >= 0 && prevIndex < keysLength) {
    return prevIndex
  }

  return Math.floor(keysLength / 2)
}

function getTopLevelNodeKeys(editor) {
  const root = editor.getEditorState()._nodeMap.get('root')
  return root ? root.__children : []
}

function getBlockElement(anchorElem, editor, event) {
  const anchorElementRect = anchorElem.getBoundingClientRect()
  const topLevelNodeKeys = getTopLevelNodeKeys(editor)

  let blockElem = null

  editor.getEditorState().read(() => {
    let index = getCurrentIndex(topLevelNodeKeys.length)
    let direction = Indeterminate

    while (index >= 0 && index < topLevelNodeKeys.length) {
      const key = topLevelNodeKeys[index]
      const elem = editor.getElementByKey(key)
      if (elem === null) {
        break
      }
      const point = new Point(event.x, event.y)
      const domRect = Rect.fromDOM(elem)
      const { marginTop, marginBottom } = window.getComputedStyle(elem)

      const rect = domRect.generateNewRect({
        bottom: domRect.bottom + parseFloat(marginBottom),
        left: anchorElementRect.left,
        right: anchorElementRect.right,
        top: domRect.top - parseFloat(marginTop)
      })

      const {
        result,
        reason: { isOnTopSide, isOnBottomSide }
      } = rect.contains(point)

      if (result) {
        blockElem = elem
        prevIndex = index
        break
      }

      if (direction === Indeterminate) {
        if (isOnTopSide) {
          direction = Upward
        } else if (isOnBottomSide) {
          direction = Downward
        } else {
          // stop search block element
          direction = Infinity
        }
      }

      index += direction
    }
  })

  return blockElem
}

function isOnMenu(element) {
  return !!element.closest(`.${SECTION_BLOCK_MENU_CLASSNAME}`)
}

function isSVGElement(element) {
  return element instanceof SVGElement
}

function useFloatingSectionEditorToolbar(editor, props) {
  const { anchorElem = document.body, handleOpenSection = () => {} } = props

  const [sectionBlockElem, setSectionBlockElem] = useState(null)
  const scrollerElem = anchorElem.parentElement
  const anchorChild = anchorElem.firstElementChild
  const [activeEditor, setActiveEditor] = useState(editor)
  const [anchorElement, setAnchorElement] = useState(null)

  useEffect(() => {
    function onMouseMove(event) {
      const target = event.target
      if (!isHTMLElement(target) && !isSVGElement(target)) {
        setSectionBlockElem(null)
        return
      }
      if (isOnMenu(target)) {
        return
      }
      const _elem = getBlockElement(anchorElem, editor, event)
      if (_elem?.className === 'editor-section' && _elem?.id) {
        setSectionBlockElem(_elem)
      }
    }

    function onMouseLeave() {
      setSectionBlockElem(null)
    }

    function onScroll() {
      setSectionBlockElem(null)
    }

    scrollerElem?.addEventListener('mousemove', onMouseMove)
    scrollerElem?.addEventListener('mouseleave', onMouseLeave)
    anchorChild?.addEventListener('scroll', onScroll)

    return () => {
      scrollerElem?.removeEventListener('mousemove', onMouseMove)
      scrollerElem?.removeEventListener('mouseleave', onMouseLeave)
      anchorChild?.addEventListener('scroll', onScroll)
    }
  }, [scrollerElem, editor, anchorElem, anchorChild])

  useEffect(() => {
    editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        setActiveEditor(newEditor)
        return false
      },
      COMMAND_PRIORITY_CRITICAL
    )
    editor.registerCommand(
      KEY_ENTER_COMMAND,
      (event) => {
        const selection = $getSelection()
        if (!$isRangeSelection(selection)) {
          return false
        }
        if (event !== null) {
          if ((IS_IOS || IS_SAFARI) && CAN_USE_BEFORE_INPUT) {
            return false
          }
          event.preventDefault()
          if (event.shiftKey && (event.ctrlKey || event.metaKey)) {
            const selection = $getSelection()
            if ($isRangeSelection(selection)) {
              const node = selection.anchor.getNode()
              const parentNode = getTopMostParent(node)
              if ($isSectionElementNode(parentNode)) {
                parentNode.insertNewAfter()
                return true
              }
            }
          } else if (event.shiftKey) {
            return editor.dispatchCommand(INSERT_LINE_BREAK_COMMAND, false)
          }
        }
        return editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND, undefined)
      },
      COMMAND_PRIORITY_CRITICAL
    )
  }, [editor])

  const menuRef = useRef(null)

  useEffect(() => {
    const currentRef = menuRef.current
    if (currentRef) {
      if (!sectionBlockElem) {
        currentRef.style.opacity = '0'
        currentRef.style.transform = 'translate(-10000px, -10000px)'
        return
      }

      const targetRect = sectionBlockElem.getBoundingClientRect()
      const targetStyle = window.getComputedStyle(sectionBlockElem)
      const currentRefRect = currentRef.getBoundingClientRect()
      const anchorElementRect = anchorElem.getBoundingClientRect()

      const top =
        targetRect.top +
        (parseInt(targetStyle.lineHeight, 10) - currentRefRect.height) / 2 -
        anchorElementRect.top +
        parseInt(targetStyle.lineHeight, 10)
      if (top < 0) {
        currentRef.style.opacity = '1'
        currentRef.style.transform = `translate(-10000px, -10000px)`
      } else {
        const left = SPACE
        currentRef.style.opacity = '1'
        currentRef.style.transform = `translate(${left}px, ${top}px)`
      }
    }
  }, [sectionBlockElem, anchorElem])

  const handleOpenSectionEditor = (e) => {
    if (sectionBlockElem) {
      setAnchorElement(sectionBlockElem)
      handleOpenSection(sectionBlockElem?.id)
    }
    activeEditor.update(() => {
      const otherElements = $getSectionOtherElementsById(sectionBlockElem?.id)
      if (otherElements) {
        otherElements.forEach((element) => {
          element?.toggleBorder(false)
        })
      }
    })
  }

  return createPortal(
    <>
      <div
        className="floating-section"
        ref={menuRef}
        onClick={(e) => {
          handleOpenSectionEditor(e)
        }}
      >
        <AutoAwesomeIcon sx={{ fontSize: '16px' }} />
      </div>
    </>,
    anchorElem
  )
}

export default function FloatingSectionEditorPlugin(props) {
  const [editor] = useLexicalComposerContext()
  return useFloatingSectionEditorToolbar(editor, props)
}
