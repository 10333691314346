import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import TextInput from '../../components/Lexical/ui/TextInput'
import Button2 from '../../components/Button/Button2'
import styled from '@emotion/styled'
import {
  CrossIcon,
  PencilIcon,
  PlusIcon,
  SemiCircleIcon,
  TrashIcon
} from '../../components/Icons/Icons'
import {
  ImageAvatarFallback,
  SelectDropDown,
  useConfirmation
} from '../../components'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useDispatch } from 'react-redux'
import { mergePeople } from '../../store/People/Actions'
import { toast } from 'react-toastify'
import { deleteGoupProfile, updateProperties } from '../../store/api'

const BulkUpdateModal = ({
  isOpen,
  selectedList,
  onSuccess,
  onClose,
  s3Obj,
  type
}) => {
  const [collection, setCollection] = useState(null)
  const [isPrivate, setIsPrivate] = useState(false)
  const [collectionName, setCollectionName] = useState('')
  const [loading, setLoading] = useState(false)
  const [finalPerson, setFinalPerson] = useState(null)
  const [finalProperties, setFinalProperties] = useState(null)
  const [selectedData, setSelectedData] = useState(selectedList)
  const [profileImages, setProfileImages] = useState([])
  const [editProfileImage, setEditProfileImage] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState('')
  const [groupIds, setGroupIds] = useState(
    selectedData.map((person) => person.id)
  )
  const [uploadedImages, setUploadedImages] = useState([])
  const dispatch = useDispatch()
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()

  const handleBulkUpdate = async () => {
    setLoading(true)

    const body = {
      group_ids: groupIds,
      group_type: type,
      property: finalProperties
    }

    // const id = toast.loading('Please wait...')

    const event = {
      people: mixpanelEvents.PEOPLE_BULK_UPDATE,
      client: mixpanelEvents.CLIENT_BULK_UPDATE,
      project: mixpanelEvents.PROJECT_BULK_UPDATE
    }

    trackEvent(
      event[type],
      'SUCCESS',
      {},
      {
        group_ids: groupIds,
        group_type: type,
        property: finalProperties
      }
    )
    // setIsMerge('loading')

    const callback = () => {
      // setIsMerge(false)
      // setSelectedData([])
      // toast.dismiss(id)

      console.log('success')
      setLoading(false)

      onSuccess()
    }

    const fallback = () => {
      // setIsMerge(true)
      // toast.dismiss(id)
      toast.error('Failed to update properties')
      setLoading(false)
    }

    updateProperties(body).then(callback).catch(fallback)
  }

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  const handleBulkDelete = () => {
    showConfirmDialog({
      onConfirm: async () => {
        const event = {
          people: mixpanelEvents.PEOPLE_BULK_DELETE,
          client: mixpanelEvents.CLIENT_BULK_DELETE,
          project: mixpanelEvents.PROJECT_BULK_DELETE
        }

        deleteGoupProfile({
          group_ids: groupIds,
          group_type: type
        })
          .then(() => {
            trackEvent(
              event[type],
              'SUCCESS',
              {},
              {
                group_ids: groupIds,
                group_type: type,
                property: finalProperties
              }
            )
            onSuccess()
          })
          .catch(() => {
            toast.error('Failed to delete')
          })
      },
      deleteType: true,
      confirmationMessageTitle: `Are you sure you want to delete ?`,
      confirmationMessage:
        'This action is irreversible. Please confirm to proceed.'
    })
  }

  const dropdownValues = {
    people: {
      label: 'Employee type',
      key: 'employee_type',
      options: [
        { label: 'Internal', value: 'internal' },
        { label: 'External', value: 'external' },
        { label: 'Unknown', value: 'unknown' }
      ]
    },

    client: {
      label: 'Client type',
      key: 'client_type',
      options: [
        { label: 'Client', value: 'client' },
        { label: 'Partner', value: 'partner' },
        { label: 'Competitor', value: 'competitor' }
      ]
    },

    project: {
      label: 'Project type',
      key: 'project_type',
      options: [
        { label: 'Internal', value: 'internal' },
        { label: 'External', value: 'external' },
        { label: 'Unknown', value: 'unknown' }
      ]
    }
  }

  return (
    <Dialog
      maxWidth="lg"
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '10px',
          padding: '14px',
          maxHeight: '450px',
          overflow: 'hidden',
          overflowY: 'auto'
          // height: '110px'
        }
      }}
    >
      <div
        className="flex flex-col justify-between h-full relative"
        style={{
          width: '400px',
          maxHeight: '450px'
          // overflow: 'auto'
        }}
      >
        <div className="">
          <p className="m-0 text-sm px-2  font-medium">
            Selected{' '}
            {type === 'people'
              ? 'People'
              : type === 'client'
              ? 'Companies'
              : 'Projects'}
            <span className="bg-grey-100 rounded-full p-1 text-xs px-2">
              {selectedData.length}
            </span>
          </p>
          <div
            className="px-3 p-1"
            style={{
              minHeight: '100px',
              maxHeight: '260px',
              overflow: 'auto'
            }}
          >
            {selectedData.map((record, index) => {
              return (
                <div className="flex items-center gap-2 mt-3" key={index}>
                  <div className="flex items-center gap-2">
                    <ImageAvatarFallback
                      profilePic={record?.profile_picture}
                      s3Obj={s3Obj}
                      type={type}
                      name={record?.name ?? record?.title}
                      style={{
                        width: '35px',
                        height: '35px',
                        objectFit: 'cover',
                        borderRadius: '20px',
                        fontSize: '12px',
                        background: 'var(--grey-700)',
                        border: '1px solid var(--grey-200)'
                      }}
                    />
                  </div>
                  <div>
                    <div className="flex items-center gap-1">
                      <p className="m-0 font-medium text-xs">
                        {record?.name ?? record?.title}
                      </p>
                    </div>
                  </div>
                  {selectedData.length > 2 && (
                    <Button2
                      secondary
                      // noOutline
                      onClick={() => {
                        const finalPeople = selectedData.filter(
                          (item) => item.id !== record.id
                        )
                        setSelectedData(finalPeople)

                        setGroupIds(
                          groupIds.filter((item) => item !== record.id)
                        )

                        // initMerge()
                      }}
                      style={{
                        marginLeft: 'auto',
                        padding: '2px 6px',
                        fontSize: '10px'
                      }}
                    >
                      Deselect
                    </Button2>
                  )}
                </div>
              )
            })}
          </div>
        </div>

        <div className="text-left mt-4 px-2">
          <p className="m-0 text-sm font-medium px-1">Properties</p>
          <span className="text-xs text-grey-600 ml-1">
            {dropdownValues[type].label}
          </span>
          <div className="mt-1">
            <SelectDropDown
              name="employeeType"
              label=""
              value={finalProperties?.value}
              options={dropdownValues[type].options}
              handleChange={(e) => {
                setFinalProperties({
                  type: dropdownValues[type].key,
                  value: e
                })
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2 mt-4 ml-1 bg-white w-full">
        <div>
          <Button2
            onClick={handleBulkDelete}
            secondary
            noOutline
            style={{
              color: '#ff4444',
              gap: '4px',
              border: '0.5px solid #ff4444'
            }}
          >
            <TrashIcon
              className="size-3"
              style={{
                color: '#ff4444'
              }}
            />
            Delete
          </Button2>
        </div>
        <div className="flex gap-1 ml-auto">
          <Button2 variant="outlined" onClick={onClose} secondary noOutline>
            Cancel
          </Button2>
          <Button2
            variant="outlined"
            onClick={handleBulkUpdate}
            primary
            // disabled={collectionName === ''}
          >
            {loading ? (
              <SemiCircleIcon className="text-grey-200 animate-spin size-4" />
            ) : (
              'Update'
            )}
          </Button2>
        </div>
      </div>{' '}
      {ConfirmDialog}
    </Dialog>
  )
}

export default BulkUpdateModal
