import { FETCH_ANALYTIC, FETCH_CHART_DATA } from '../../../actionTypes'
import {
  getAnalytic,
  getChartData,
  getAnalyticWithoutUser,
  getChartDataWithoutUser
} from '../../../api'
import _ from 'lodash'

const fetchAnalytic =
  (
    analyticId = '',
    domainId = '',
    shareId = '',
    startPage = 0,
    callback = () => {}
  ) =>
  async (dispatch) => {
    const initialPayload = {}
    dispatch({ type: FETCH_ANALYTIC, payload: initialPayload })

    const fetchChartData = domainId
      ? getChartDataWithoutUser({ id: shareId })
      : getChartData({ id: analyticId })

    const chartRes = await fetchChartData
    if (chartRes.status === 200) {
      const { resultsets } = chartRes.data
      dispatch({ type: FETCH_CHART_DATA, payload: resultsets })
    }

    const pageCount = 50
    const start = startPage || 1
    const end = start + pageCount
    let totalPages = 0
    let currentPage = start
    let analyticData = {}

    const fetchAnalyticData = domainId
      ? getAnalyticWithoutUser(shareId, currentPage, end, domainId)
      : getAnalytic(analyticId, currentPage, end)

    const res = await fetchAnalyticData
    if (res.status === 200) {
      const result = res.data
      analyticData = {
        ...result,
        metadata: {
          ...analyticData.metadata,
          ...transformMetaData(result.metadata)
        }
      }
      totalPages = parseInt(result.total_pages)
      currentPage += pageCount + 1

      callback()
      dispatch({ type: FETCH_ANALYTIC, payload: analyticData })
    } else {
      currentPage = totalPages + 1
    }
  }

const transformMetaData = (obj) => {
  let transformedObj = Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      Object.entries(value).map(([id, data]) => {
        const err = {
          id,
          ...data,
          errorType: key,
          pageNo: data?.pageIndex,
          pageIndexDup: Array.isArray(data?.pageIndex)
            ? _.sortBy(data?.pageIndex.map((item) => _.toInteger(item)))
            : _.toInteger(data?.pageIndex)
        }
        if (
          _.isObject(err?.keyword) &&
          'original' in err.keyword &&
          'correction' in err.keyword
        ) {
          const { original, correction } = { ...err.keyword }
          err.keyword = original
          err.keywordCorrection = correction
        }
        if (!err.sentence) {
          err.sentence = err?.keyword ? err.keyword : ''
        }
        if (!err?.keyword) {
          err.keyword = err?.sentence ? err.sentence : ''
        }
        if (err?.errorType === 'grammar') {
          if (err?.keyword) {
            if (err?.keyword.replace(/^\s+/g, '').length > 3) {
              return err
            }
          }
        } else {
          return err
        }
        return err
      })
    ])
  )
  delete transformedObj.entities
  let transformedEntitiesObj = {}
  if (obj?.entities) {
    transformedEntitiesObj = Object.fromEntries(
      Object.entries(obj.entities).map(([key, value]) => [
        key,
        Object.entries(value).map(([id, data]) => {
          const err = {
            id,
            ...data,
            errorType: key,
            pageNo: data?.pageIndex,
            pageIndexDup: Array.isArray(data?.pageIndex)
              ? _.size(data?.pageIndex) > 1
                ? data?.pageIndex.map((item) => _.toInteger(item))
                : _.toInteger(data?.pageIndex[0])
              : _.toInteger(data?.pageIndex)
          }
          return err
        })
      ])
    )
  }
  transformedObj = { ...transformedObj, ...transformedEntitiesObj }

  try {
    function sortByProperty(property) {
      return function (a, b) {
        return a[property] - b[property]
      }
    }

    function sortArraysInsideObject(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && Array.isArray(obj[key])) {
          obj[key].sort(sortByProperty('pageNo'))
        }
      }
    }

    sortArraysInsideObject(transformedObj)
  } catch (e) {
    console.log(e)
  }

  return transformedObj
}

export default fetchAnalytic
