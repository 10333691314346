import {
  UPDATE_PEOPLE,
  DELETE_PEOPLE,
  MERGE_PEOPLE,
  UPDATE_PEOPLE_NAME,
  UPDATE_PEOPLE_NOTES
} from '../../../actionTypes'
import {
  setPeopleVerify,
  deleteMasterResume,
  deleteMasterResumeMapping,
  mergePeopleRequest,
  changeMasterPeopleName,
  changePeopleInfo,
  updatePeopleResume,
  uploadFileToS3,
  moveResumeRequest
} from '../../../api'
import { toast } from 'react-toastify'

const updatePeople = (data, callback) => async (dispatch) => {
  const res = await setPeopleVerify(data)
  if (res.status === 200) {
    dispatch({
      type: UPDATE_PEOPLE,
      payload: data,
      callback
    })
  }
}

const deletePeople = (id, deleteType, callback) => async (dispatch) => {
  const res = await deleteMasterResume(id, deleteType)
  if (res.status === 200) {
    dispatch({
      type: DELETE_PEOPLE,
      payload: id,
      callback
    })
    toast.success('Successfully deleted resume')
  } else {
    toast.success('Failed to delete resume')
    callback()
  }
}

const deletePeopleMapping = (data, callback) => async (dispatch) => {
  const res = await deleteMasterResumeMapping(data)
  if (res.status === 200) {
    dispatch({
      type: UPDATE_PEOPLE,
      payload: data,
      callback
    })
    toast.success('Successfully action performed on resume mapping')
  } else {
    toast.error('Failed to delete resume mapping')
    callback()
  }
}

const mergePeople = (data, callback, fallback) => async (dispatch) => {
  const res = await mergePeopleRequest(data)
  if (res.status === 200) {
    dispatch({
      type: MERGE_PEOPLE,
      payload: data,
      callback
    })
    toast.success('Successfully merged people')
  } else {
    toast.error('Failed to merge people')
    fallback()
  }
}

const moveResume = (data, callback) => async (dispatch) => {
  const res = await moveResumeRequest(data)
  if (res.status === 200) {
    // dispatch({
    //   type: MERGE_PEOPLE,
    //   payload: data,
    //   callback
    // })
    toast.success('Successfully moved resume')
    callback()
  } else {
    toast.error('Failed to move resume')
    callback()
  }
}

const updatePeopleName = (data, callback) => async (dispatch) => {
  const res = await changeMasterPeopleName(data)
  if (res.status === 200) {
    dispatch({
      type: UPDATE_PEOPLE_NAME,
      payload: data,
      callback
    })
  }
}

const updatePeopleInfo =
  (data, callback, skipDispatch = false) =>
  async (dispatch) => {
    const res = await changePeopleInfo(data)
    if (res.status === 200) {
      if (!skipDispatch) {
        dispatch({
          type: UPDATE_PEOPLE_NAME,
          payload: data,
          callback
        })
      } else {
        callback()
      }
    }

    if (res.status === 200 && skipDispatch) {
      callback()
    }
  }

const updatePeopleNotes =
  (data, callback, skipDispatch = false) =>
  async (dispatch) => {
    const res = await changePeopleInfo(data)
    if (res.status === 200 && !skipDispatch) {
      callback()
      // dispatch({
      //   type: UPDATE_PEOPLE_NOTES,
      //   payload: data,
      //   callback
      // })
    }
  }

const updateParsedResumeData = (data, callback) => async (dispatch) => {
  const res = await updatePeopleResume(data)

  if (res.status === 200) {
    callback()
  }
}

const changeProfileImage = (data, callback) => async (dispatch) => {
  const res = await changePeopleInfo(data)
  if (res.status === 200) {
    callback(res)
  }
}

const uploadResumeImage = (data, callback) => async (dispatch) => {
  const res = await uploadFileToS3(data)
  if (res.status === 200) {
    callback(res)
  }
}

export {
  updatePeople,
  deletePeople,
  deletePeopleMapping,
  mergePeople,
  updatePeopleName,
  updatePeopleInfo,
  updateParsedResumeData,
  uploadResumeImage,
  updatePeopleNotes,
  moveResume,
  changeProfileImage
}
