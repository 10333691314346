import React, { useEffect, useState } from 'react'
import { useStyles } from './styles'
import { Box, Collapse } from '@mui/material'
import { Image } from '../../components'
import { capitalize } from 'lodash'
import clsx from 'clsx'
import CopyButton from '../../components/CopyButton'
import TextResult from '../TextResult'
import ImageResult from '../ImageResult'
import LibraryCard from '../LibraryCard'
import { AssetType } from '../AssetList'
import { getSignedUrl } from '../../utils/AWS'
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom'
import Variations from '../TextResult/Variations'
import { useSelector } from 'react-redux'
import { ROUTES } from '../../config/routes'

const Result = (props) => {
  const {
    res = {},
    result_type = '',
    index,
    searchType,
    keyword,
    s3Obj,
    assetStyleProps = {},
    disableEdit = false,
    handleFilterByProposal = () => {},
    showTempDataTag = false,
    handleShowImage = () => {},
    setSelectedFile = () => {},
    selectedFile = '',
    handleCopy = () => {},
    src = ''
  } = props

  const { library_id = '' } = res
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { id: userId = '', domain_id = '' } = domain || {}
  const classes = useStyles()
  const location = useLocation()
  const path = location?.pathname
  const {
    imageContainer,
    copyContainer,
    defaultHeight,
    newLineCharaters,
    content,
    expandContent,
    collapseContent,
    titleWapper,
    titleWapperPDF,
    textWrapper,
    fileNameWapper,
    assetWrapper,
    assetRootWrapper
  } = classes
  const [assetData, setAssetData] = useState({})
  const [selectedAsset, setSelectedAsset] = useState({})
  const [showVariations, setShowVariations] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    async function initalizeData() {
      setAssetData(res)
    }
    if (res) {
      initalizeData()
    }
  }, [res, searchType])

  const openAsset = (e, asset) => {
    if (!disableEdit) {
      const { id, src, resultType, fileName } = asset
      let asset_type = resultType
      if (resultType === 'video') {
        const { data } = asset || {}
        const { metadata } = data || {}
        const { format } = metadata || {}
        asset_type = format
      }
      const file = {
        id,
        file_type: src === 'proposal' ? 'proposal image' : 'asset',
        file_name: fileName,
        other_data: {
          asset_type
        }
      }
      setSelectedFile(file)
    }
  }

  const openPDFViewer = (e) => {
    if (!disableEdit) {
      const {
        proposal_id,
        content_id,
        document_id,
        proposal_filename,
        page_number
      } = e
      const file = {
        id: proposal_id || document_id,
        file_type: proposal_id ? 'proposal' : 'document',
        file_name: proposal_filename,
        other_data: {
          page_number,
          content_id
        }
      }
      setSelectedFile(file)
    }
  }

  const openNarrative = (e, title) => {
    if (!disableEdit) {
      const file = {
        id: e,
        file_type: 'knowledge',
        file_name: title,
        other_data: { content_id: e }
      }
      setSelectedFile(file)
    }
  }

  const openProject = (e, content) => {
    if (!disableEdit) {
      const { content_id } = e

      const { id, project_name } = content
      const file = {
        id,
        file_type: 'project',
        file_name: project_name,
        other_data: { ...content, content_id }
      }
      setSelectedFile(file)

      // window.open(`library/projects/${id}`, '_blank')
    }
  }

  const handleVariation = () => {
    setShowVariations(!showVariations)
  }

  if (result_type === 'section') {
    const { data = {}, screenshots = [], thumbnail_location = '' } = res
    const { content, title } = data

    return (
      <>
        <TextResult
          result={res}
          result_type={result_type}
          hideTextLength={300}
          optionHiddenOpen={false}
          handleCopy={() => handleCopy(content, title)}
          handlePDFViewer={openPDFViewer}
          selectedFile={selectedFile}
          enableSave={false}
          searchType={searchType}
          searchKeyword={keyword}
          showVariations={showVariations}
          handleVariation={handleVariation}
          handleShowImage={handleShowImage}
          screenshots={screenshots}
          s3Obj={s3Obj}
          enableAddtoNarratives
          disableEdit={disableEdit}
          showTempDataTag={showTempDataTag}
          src={src}
          handleFilterByProposal={handleFilterByProposal}
          keyId={index}
        />
        {showVariations && (
          <Variations
            disableEdit={disableEdit}
            domain_id={domain_id}
            handleCopy={handleCopy}
            variationData={res}
            setShowVariations={setShowVariations}
            showVariations={showVariations}
            handlePDFViewer={openPDFViewer}
            s3Obj={s3Obj}
            src={src}
            handleFilterByProposal={handleFilterByProposal}
          />
        )}
      </>
    )
  } else if (result_type === 'narrative') {
    const { data = {}, library_id, variationsFromApiKnowledge } = res
    return (
      <>
        <LibraryCard
          data={{ ...data, id: library_id, variationsFromApiKnowledge, ...res }}
          userId={userId}
          result_type={result_type}
          searchType={searchType}
          isDeleteable={false}
          handleVariation={handleVariation}
          showVariations={showVariations}
          onPDFClick={openPDFViewer}
          onTitleClick={openNarrative}
          disableEdit={disableEdit}
          src={src}
          selectedFile={selectedFile}
        />
        {showVariations && (
          <Variations
            domain_id={domain_id}
            handleCopy={handleCopy}
            variationData={res}
            setShowVariations={setShowVariations}
            showVariations={showVariations}
            handlePDFViewer={openPDFViewer}
            s3Obj={s3Obj}
            disableEdit={disableEdit}
            src={src}
          />
        )}
      </>
    )
  } else if (
    result_type === 'asset' ||
    result_type === 'image' ||
    result_type === 'video'
  ) {
    return (
      <AssetType
        styleProps={assetStyleProps}
        asset={assetData}
        selectedAsset={selectedAsset}
        keyIndex={index}
        key={index}
        parentProps={{ onClickFile: openAsset, s3Obj }}
        allowDownload={false}
        srcType={src}
      />
    )
  } else if (result_type === 'project') {
    const { data = {}, thumbnail_location = '' } = res
    const screenshots = [{ src: thumbnail_location }]
    const { content, title } = data

    let project_desc

    try {
      project_desc = JSON.parse(content.replace(/'/g, '"'))
    } catch (e) {
      project_desc = {}
    }

    return (
      <>
        <TextResult
          result={res}
          result_type={result_type}
          hideTextLength={300}
          optionHiddenOpen={false}
          handleCopy={() =>
            handleCopy(project_desc?.project_description, title)
          }
          handlePDFViewer={openPDFViewer}
          selectedFile={selectedFile}
          handleProjectViewer={openProject}
          enableSave={false}
          searchType={searchType}
          searchKeyword={keyword}
          showVariations={showVariations}
          handleVariation={handleVariation}
          handleShowImage={handleShowImage}
          screenshots={screenshots}
          s3Obj={s3Obj}
          enableAddtoNarratives
          disableEdit={disableEdit}
          showTempDataTag={showTempDataTag}
          src={src}
          handleFilterByProposal={handleFilterByProposal}
          keyId={index}
        />
        {showVariations && (
          <Variations
            result_type={result_type}
            disableEdit={disableEdit}
            domain_id={domain_id}
            handleCopy={handleCopy}
            variationData={res}
            setShowVariations={setShowVariations}
            showVariations={showVariations}
            handlePDFViewer={openPDFViewer}
            s3Obj={s3Obj}
            src={src}
            handleProjectViewer={openProject}
            handleFilterByProposal={handleFilterByProposal}
          />
        )}
      </>
    )
  }
  return <></>
}

export default Result
