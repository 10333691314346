import { Tooltip, tooltipClasses } from '@mui/material'
import React, { useEffect } from 'react'
import { styled } from '@mui/styles'
import Fade from '@mui/material/Fade'
import { CheckIcon, CrossIcon, EyeIcon, EyeOffIcon } from '../Icons/Icons'
const InputGroup = ({
  label = '',
  onChange,
  type = 'text',
  value,
  required,
  error,
  helperText,
  placeholder
}) => {
  const [showPasswordError, setShowPasswordError] = React.useState(false)
  const [hidePassword, setHidePassword] = React.useState(true)
  const [inputType, setInputType] = React.useState(type)
  const isPassowordInput = type === 'password'
  useEffect(() => {
    if (isPassowordInput && error) {
      setShowPasswordError(true)
    }
  }, [error])

  useEffect(() => {
    if (isPassowordInput) {
      if (hidePassword) {
        setInputType('password')
      } else {
        setInputType('text')
      }
    }
  }, [hidePassword])

  return (
    <div className="text-left">
      <div className="flex justify-between items-center">
        <label className="text-xs text-grey-500">
          {label}
          {required && (
            <span
              style={{
                color: '#ff3e3e'
              }}
            >
              {' '}
              *
            </span>
          )}
        </label>
        {error && label !== 'Password' && (
          <p className="text-xxs m-0" style={{ color: '#ff3e3e' }}>
            {helperText}
          </p>
        )}

        {label === 'Password' && (
          <HtmlTooltip
            placement="bottom"
            trigger="click"
            open={showPasswordError}
            onClose={() => {
              setShowPasswordError(null)
            }}
            onOpen={() => {
              setShowPasswordError(true)
            }}
            disableFocusListener={false}
            disableHoverListener={false}
            title={
              <div className="p-2 flex flex-col gap-1">
                {checkPasswork(value)}
              </div>
            }
          >
            <span
              className="text-xs mr-1"
              style={{
                color: error ? '#ff3e3e' : 'var(--grey-700)',
                cursor: 'pointer'
              }}
            >
              ?
            </span>
          </HtmlTooltip>
        )}
      </div>
      <div className="bg-grey-100 flex items-center gap-2">
        <input
          type={inputType}
          className="input-base bg-grey-100 p-2 text-sm w-full border-box rounded-md input-base-placeholder"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />

        {isPassowordInput ? (
          hidePassword ? (
            <EyeOffIcon
              className="size-4 mr-3"
              onClick={() => {
                setHidePassword(false)
              }}
            />
          ) : (
            <EyeIcon
              className="size-4 mr-3"
              onClick={() => {
                setHidePassword(true)
              }}
            />
          )
        ) : null}
      </div>
    </div>
  )
}

export default InputGroup

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    TransitionComponent={Fade}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '0',
    maxWidth: '200px',
    backgroundColor: '#131516',
    color: 'white',
    textAlign: 'left',
    width: '100%',
    marginTop: '5px',
    height: '100%',
    // border: '1px solid #dadde9',
    gap: '3px',
    display: 'flex',
    flexDirection: 'column'
  }
}))

const checkPasswork = (password) => {
  const lengthMatch = password.length >= 8 && password.length <= 20
  const upperCaseMatch = /[A-Z]/.test(password)
  const lowerCaseMatch = /[a-z]/.test(password)
  const numberMatch = /[0-9]/.test(password)

  //   return [lengthMatch, upperCaseMatch, lowerCaseMatch, numberMatch]
  const conditionLabel = (msg, value) => {
    return (
      <p className="m-0 px-1">
        {value ? (
          <CheckIcon style={{ color: '#0d9e00' }} className="size-3 mr-1" />
        ) : (
          <CrossIcon style={{ color: '#ff3e3e' }} className="size-3 mr-1" />
        )}

        {msg}
      </p>
    )
  }
  return (
    <>
      {conditionLabel('8 - 20 characters', lengthMatch)}
      {conditionLabel('At least one uppercase letter', upperCaseMatch)}
      {conditionLabel('At least one lowercase letter', lowerCaseMatch)}
      {conditionLabel('At least one number', numberMatch)}
    </>
  )
}
