import {
  UPDATE_LIBRARY_DOCUMENT,
  UPDATE_LIBRARY_SUGGESTION,
  UPDATE_LIBRARY_STATUS
} from '../../../actionTypes'
import { updateLibrary, updateLibrarySuggestions } from '../../../api'
import trackEvent from '../../../../utils/TrackEvent/TrackEvent'
import eventMapping from '../../../../config/eventMapping'
import mixpanelEvents from '../../../../config/mixpanelEvents'
import { toast } from 'react-toastify'

const updateLibraryDocument =
  (data, callback = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_LIBRARY_STATUS,
      payload: { id: data.id, status: 'updating' }
    })
    let response = await updateLibrary(data)
    if (response.status === 200) {
      response = response?.data
      dispatch({
        type: UPDATE_LIBRARY_STATUS,
        payload: { id: data.id, status: false },
        callback
      })
      trackEvent(mixpanelEvents.KNOWLEDGE_CONTENT_UPDATED, 'SUCCESS', {}, {})
      // dispatch({
      //   type: UPDATE_LIBRARY_DOCUMENT,
      //   payload: data
      // })
    } else {
      console.log('update library document failed ', response)
      trackEvent(
        mixpanelEvents.KNOWLEDGE_CONTENT_UPDATED,
        'FAILED',
        {},
        { details: response }
      )
      response = {
        error: true,
        message: 'update library document failed '
      }
      dispatch({
        type: UPDATE_LIBRARY_STATUS,
        payload: { id: data.id, status: false }
      })
      toast.error('Failed to update library document')
    }
  }

const updateNotes =
  (data, callback = () => {}) =>
  async (dispatch) => {
    const response = await updateLibrary(data)

    if (response.status === 200) {
      callback(response.data)
    }
  }
const updateLibrarySuggestion = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_LIBRARY_SUGGESTION,
    payload: data
  })
  // const response = await updateLibrarySuggestions(data)
  // if (response.status !== 200) {
  //   toast.error('Failed to update library suggestion')
  // }
}

export { updateLibraryDocument, updateLibrarySuggestion, updateNotes }
