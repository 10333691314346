import { useState, useLayoutEffect, createRef, useRef } from 'react'

const TextLineLimiter = (props) => {
  const {
    content = '',
    limit = 2,
    startAt = '',
    hideShowMore = false,
    isStartAtHTML = '',
    isControlled = false,
    controlledExpand,
    controlledSetExpand,
    showMore = true,
    width = '100%',
    wordBreak = 'break-word',
    indicatorStyle = {
      color: 'blue',
      cursor: 'pointer',
      fontStyle: 'normal',
      fontWeight: '100',
      fontSize: '13px'
    }
  } = props
  const [uncontrolledExpand, setUncontrolledExpand] = useState(false)
  const [showExpand, setShowExpand] = useState(false)
  let expand = uncontrolledExpand
  let setExpand = setUncontrolledExpand
  if (
    isControlled &&
    controlledExpand !== undefined &&
    controlledSetExpand !== undefined
  ) {
    expand = controlledExpand
    setExpand = controlledSetExpand
  }
  const [textSplited, setTextSplited] = useState(false)
  const previousContentRef = useRef()

  if (startAt) {
    const ref = createRef()

    const checkifVisibleHtml = (element) => {
      const wordToCheck = startAt
      if (element.innerHTML.includes(wordToCheck)) {
        const rect = element.getBoundingClientRect()
        const wordElement = element.querySelector(isStartAtHTML)
        if (wordElement) {
          const wordRect = wordElement.getBoundingClientRect()
          const wordVisible =
            wordRect.top >= rect.top && wordRect.bottom <= rect.bottom
          return wordVisible
        }
      }
      return false
    }

    const checkifVisibleWord = (element) => {
      const wordToCheck = startAt
      if (element.innerText.includes(wordToCheck)) {
        const rect = element.getBoundingClientRect()
        const tempElement = document.createElement('span')
        tempElement.innerText = element.innerText.replace(
          wordToCheck,
          `<span class="highlight">${wordToCheck}</span>`
        )
        tempElement.style.position = 'absolute'
        tempElement.style.visibility = 'hidden'
        document.body.appendChild(tempElement)
        const wordElement = tempElement.querySelector('.highlight')
        if (wordElement) {
          const wordRect = wordElement.getBoundingClientRect()
          const wordVisible =
            wordRect.top >= rect.top && wordRect.bottom <= rect.bottom
          document.body.removeChild(tempElement)
          return wordVisible
        }
        document.body.removeChild(tempElement)
      }
      return false
    }

    useLayoutEffect(() => {
      if (
        previousContentRef.current !== content ||
        (setShowExpand && !expand)
      ) {
        const { current } = ref
        if (startAt && current) {
          const { clientHeight, scrollHeight } = current
          if (clientHeight < scrollHeight) {
            setShowExpand(true)
            const flag = isStartAtHTML
              ? checkifVisibleHtml(current)
              : checkifVisibleWord(current)
            if (!flag) {
              const innerHTML = current.innerHTML
              const index = innerHTML.indexOf(startAt)
              if (index >= 0) {
                let mark = innerHTML.substring(index + startAt.length)
                mark = `<span>${startAt}${mark}`
                current.innerHTML = mark
              }
              setTextSplited(true)
            }
          } else {
            setShowExpand(false)
          }
        }
        previousContentRef.current = content
      }
    }, [ref])
    return (
      <>
        {showMore && (
          <>
            {!expand ? (
              <div>
                <div
                  ref={ref}
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: limit,
                    WebkitBoxOrient: 'vertical',
                    wordBreak,
                    width
                  }}
                >
                  {content}
                </div>
                {(showExpand || textSplited) && !hideShowMore && (
                  <div>
                    <span
                      style={indicatorStyle}
                      onClick={(e) => {
                        e.stopPropagation()
                        setExpand(!expand)
                      }}
                    >
                      show more
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div style={{ wordBreak }}>
                {content}
                {expand && (showExpand || textSplited) && (
                  <div>
                    <span
                      style={indicatorStyle}
                      onClick={(e) => {
                        e.stopPropagation()
                        setExpand(!expand)
                      }}
                    >
                      show less
                    </span>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </>
    )
  } else {
    const ref = createRef()
    useLayoutEffect(() => {
      if (previousContentRef.current !== content) {
        const { current } = ref
        if (current) {
          const { clientHeight, scrollHeight } = current
          if (clientHeight < scrollHeight) {
            setShowExpand(true)
          } else {
            setShowExpand(false)
          }
        }
        previousContentRef.current = content
      }
    }, [ref])

    return (
      <div>
        <div
          ref={ref}
          style={{
            overflow: expand ? 'auto' : 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: expand ? '' : limit,
            wordBreak,
            WebkitBoxOrient: 'vertical'
          }}
        >
          {content}
        </div>
        {showExpand && showMore && (
          <div>
            <span
              style={indicatorStyle}
              onClick={(e) => {
                e.stopPropagation()
                setExpand(!expand)
              }}
            >
              {expand ? 'show less' : 'show more'}
            </span>
          </div>
        )}
      </div>
    )
  }
}
export default TextLineLimiter
