import React, { Fragment, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Error from '../Error'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Loader from '../Loader'

const ConnectionChecker = (props) => {
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { error } = domain || {}
  const { userSigned } = props
  props = _.omit(props, ['userSigned'])
  const [initialLoad, setInitialLoad] = useState(true)

  if (!_.isEmpty(domain)) {
    if (initialLoad) {
      if (process.env.REACT_APP_ENV === 'production') {
        setInitialLoad(false)
      }
      if (process.env.REACT_APP_ENV === 'staging') {
        setInitialLoad(false)
      }
    }
  }

  if (_.isEmpty(domain) && userSigned) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Loader loading={true} caption={'loading your workspace'} />
      </Box>
    )
  }
  if (error) {
    return <Error errorMessage={'Oops! Cannot connect to server'} />
  }
  return <Fragment {...props}>{props.children}</Fragment>
}

export default ConnectionChecker
