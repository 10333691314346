import React, { useState, useEffect } from 'react'
import { TextField, Typography, Box } from '@mui/material'
import indesign from '../../assets/svg/Indesign.svg'
import Logo from '../../assets/images/JoistLogo.png'
import Button from '../../components/Button/Button2'
import { toast } from 'react-toastify'
const IndesignSSO = (props) => {
  const { indesignCode, setIndesignCode } = props
  const [code, setCode] = useState('')
  const copyToClipboard = () => {
    navigator.clipboard.writeText(code).then(
      () => toast.success('Copied to clipboard!'),
      () => toast.error('Failed to copy to clipboard!')
    )
  }
  useEffect(() => {
    if (indesignCode) {
      setCode(indesignCode)
    }
  }, [indesignCode])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        gap: '20px'
      }}
    >
      <img
        src={indesign}
        alt="indesign"
        style={{
          height: '30vh'
        }}
      />
      <Box
        sx={{
          maxWidth: '500px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <img src={Logo} alt="applogo" style={{ width: '300px' }} />
        <Typography
          sx={{ marginTop: '20px', textAlign: 'center' }}
          variant="h5"
        >
          Looks like you are trying to login to our indesign app. Please copy
          the below url and paste it in the app to continue.
        </Typography>
        {code && (
          <Box>
            <TextField
              value={`${code}`}
              InputProps={{
                readOnly: true
              }}
              margin="normal"
              disabled
              sx={{ width: '300px' }}
            />
          </Box>
        )}
        {code && (
          <Button variant="contained" color="primary" onClick={copyToClipboard}>
            Copy
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default IndesignSSO
